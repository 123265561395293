.root {
  position: relative;
  display: flex;
  align-items: flex-start;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.minibarWrapper {
  display: flex;
  align-items: center;
  height: 16px;
}

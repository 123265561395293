.root {
  height: 100%;
  padding: 0 0.5rem;
  text-align: right;
  color: var(--mb-color-text-light);
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  border-bottom: 1px solid var(--mb-color-border-alpha-30);
}

.rowNumber {
  line-height: 16px;
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
}

.expandButton {
  display: none;
  flex-shrink: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.root:hover > .expandButton {
  display: block;
}

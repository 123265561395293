.root {
  display: flex;
  width: 100%;
  height: 100%;
  font-weight: 700;
  align-items: flex-start;
  padding-top: 9px;
  padding-bottom: 9px;
  cursor: pointer;
}

.content {
  line-height: 17px;
  overflow: visible;
}

.noWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clickable {
  cursor: pointer;
}

.pill {
  padding-top: 6px;
  padding-bottom: 6px;
}

.pill .content {
  border-radius: 99px;
  padding: 1.5px 8px;
  height: 22px;
  width: auto;
  min-width: 30px;
  text-overflow: ellipsis;
  text-align: center;
}
